import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Link } from 'react-router-dom';  // Import Link for navigation
import { db } from './firebase';  // Firestore import
import './Assets.css';
import AddAsset from './AddAsset';  // Import AddAsset component for adding new assets

function Assets({ showNotification }) {  // Accept showNotification as a prop
  const [assets, setAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);

  // Fetch assets from Firestore
  const fetchAssets = async () => {
    try {
      const assetCollection = collection(db, 'Assets');
      const assetSnapshot = await getDocs(assetCollection);
      const assetList = assetSnapshot.docs.map((doc) => ({
        id: doc.id,  // Document ID
        ...doc.data(),  // All the fields in the document
      }));
      setAssets(assetList);
    } catch (error) {
      console.error('Error fetching assets:', error);
      showNotification('Failed to fetch assets', 'error');  // Show error notification
    }
  };

  // Fetch assets when the component mounts
  useEffect(() => {
    fetchAssets();
  }, []);

  // Handle asset selection for Delete/Edit
  const handleSelectAsset = (assetID) => {
    const updatedSelectedAssets = selectedAssets.includes(assetID)
      ? selectedAssets.filter((id) => id !== assetID)
      : [...selectedAssets, assetID];
    setSelectedAssets(updatedSelectedAssets);
  };

  // Delete selected assets
  const handleDelete = async () => {
    try {
      for (const assetID of selectedAssets) {
        await deleteDoc(doc(db, 'Assets', assetID));
      }
      showNotification('Selected assets deleted successfully', 'success');  // Show success notification
      setSelectedAssets([]);  // Clear selection
      fetchAssets();  // Refresh asset list
    } catch (error) {
      console.error('Error deleting assets:', error);
      showNotification('Failed to delete selected assets', 'error');  // Show error notification
    }
  };

  // Show Add Asset form
  const handleAdd = () => {
    setShowAddForm(true);
  };

  // Close Add Asset form
  const handleCloseAddForm = () => {
    setShowAddForm(false);
    fetchAssets();  // Refresh asset list after adding
  };

  return (
    <div className="assets-container">
      <h1>Assets</h1>
      <div className="button-container">
        <button className="btn" onClick={handleAdd}>Add</button>
        <button
          className={`btn ${selectedAssets.length === 0 ? 'disabled' : ''}`}
          onClick={handleDelete}
          disabled={selectedAssets.length === 0}
        >
          Delete
        </button>
        <button
          className={`btn ${selectedAssets.length === 0 ? 'disabled' : ''}`}
          disabled={selectedAssets.length === 0}
        >
          Edit
        </button>
      </div>

      {/* Show AddAsset form if showAddForm is true */}
      {showAddForm && (
        <AddAsset 
          onClose={handleCloseAddForm} 
          showNotification={showNotification}  // Pass showNotification to AddAsset
        />
      )}

      {/* Display assets in a table */}
      <table className="assets-table">
        <thead>
          <tr>
            <th>Select</th>
            <th>Asset ID</th>
            <th>Name</th>
            <th>Serial Number</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {assets.length > 0 ? (
            assets.map((asset) => (
              <tr key={asset.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedAssets.includes(asset.id)}
                    onChange={() => handleSelectAsset(asset.id)}
                  />
                </td>
                <td>
                  {/* Make Asset ID clickable and link to the AssetDetail page */}
                  <Link to={`/assets/${asset.id}`}>{asset.assetID}</Link>
                </td>
                <td>{asset.name}</td>
                <td>{asset.serialNumber}</td>
                <td>{asset.active ? 'Yes' : 'No'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No assets found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Assets;