// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyDlBH6dED6plTOclR3TCyuu6KzQhc2tm-s",
  authDomain: "asset-management-300d9.firebaseapp.com",
  databaseURL: "https://asset-management-300d9-default-rtdb.firebaseio.com",
  projectId: "asset-management-300d9",
  storageBucket: "asset-management-300d9.appspot.com",
  messagingSenderId: "828089742521",
  appId: "1:828089742521:web:0c1ba911467cf8a2611768",
  measurementId: "G-EJ9P05PYMT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };