// src/Dashboard.js
import React from 'react';

function Dashboard({ user }) {
  const userName = user.displayName ? user.displayName.split(' ')[0] : 'User';

  return (
    <div>
      <h1>Welcome, {userName}!</h1>
      <p>This is your dashboard.</p>
    </div>
  );
}

export default Dashboard;