import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import './PrintPurchaseOrder.css';

function PrintPurchaseOrder({ showNotification }) {
  const { purchaseOrderID } = useParams();
  const [purchaseOrder, setPurchaseOrder] = useState(null);

  // Fetch the purchase order details
  const fetchPurchaseOrder = async () => {
    try {
      const docRef = doc(db, 'PurchaseOrders', purchaseOrderID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPurchaseOrder(docSnap.data());
        console.log('Purchase Order Data:', docSnap.data()); // Debugging log
      } else {
        showNotification('Purchase order not found', 'error');
      }
    } catch (error) {
      showNotification('Failed to fetch purchase order', 'error');
      console.error('Error fetching purchase order:', error);
    }
  };

  useEffect(() => {
    fetchPurchaseOrder();
  }, [purchaseOrderID]);

  if (!purchaseOrder) {
    return <div>Loading...</div>;
  }

  const grandTotal = typeof purchaseOrder.grandTotal === 'number' 
    ? purchaseOrder.grandTotal 
    : parseFloat(purchaseOrder.grandTotal) || 0;

  return (
    <div className="print-purchase-order-container">
      <div className="header">
        <div className="logo">
          <img src="/assets/church-logo.png" alt="Church Logo" className="logo" />
        </div>
        <div className="address">
          <p>Pentecostal Missionary Church of Christ (4th Watch)</p>
          <p>#2 Delbert Drive, Scarborough ON, M1P 1X1</p>
          <p>Tel. No: (Insert Phone Number)</p>
        </div>
        <div className="po-number">
          <p>P.O. #:</p>
          <h2 style={{ color: 'red' }}>{purchaseOrderID}</h2>
          <p className="use-only">For accounting Dept. Use Only</p>
        </div>
      </div>

      <div className="information-section">
        <div className="row">
          <div className="info-item">
            <strong>Requested By:</strong> {purchaseOrder.requestedBy}
          </div>
          <div className="info-item">
            <strong>Date of Request:</strong> {purchaseOrder.date}
          </div>
        </div>
        <div className="row">
          <div className="info-item">
            <strong>Ministry:</strong> {purchaseOrder.ministry}
          </div>
          <div className="info-item">
            <strong>Originator:</strong> {purchaseOrder.originator}
          </div>
        </div>
        <div className="row">
          <div className="info-item">
            <strong>Terms of Payment:</strong> {purchaseOrder.paymentTerms}
          </div>
          <div className="info-item">
            <strong>Payment Info:</strong> {purchaseOrder.paymentTermsInfo}
          </div>
        </div>
        <div className="row full-width">
          <strong>Purpose of Request:</strong> {purchaseOrder.purposeOfRequest}
        </div>
      </div>

      <div className="items-section">
        <h3>Items</h3>
        <table className="items-table">
          <thead>
            <tr>
              <th>Quantity</th>
              <th>Items</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrder.items.map((item, index) => (
              <tr key={index}>
                <td>{item.quantity}</td>
                <td>{item.item}</td>
                <td>${item.itemSubtotal}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="totals">
          <p><strong>Total Amount:</strong> ${grandTotal.toFixed(2)}</p>
          <p><strong>Tax (13%):</strong> ${(grandTotal * 0.13).toFixed(2)}</p>
          <p><strong>Grand Total:</strong> ${(grandTotal * 1.13).toFixed(2)}</p>
        </div>
      </div>

      <div className="signatures-section">
        <div className="signature-box">
          <p><strong>Purchased By:</strong></p>
          <div className="signature-line"></div>
          <p><strong>Approved By:</strong></p>
          <div className="signature-line"></div>
        </div>
        <div className="notes-box">
          <p><strong>Notes and Remarks:</strong></p>
          <div className="notes-area"></div>
        </div>
        <div className="signature-box">
          <p><strong>Reviewed By:</strong></p>
          <div className="signature-line"></div>
          <p><strong>Posted By:</strong></p>
          <div className="signature-line"></div>
        </div>
      </div>

      <button onClick={() => window.print()} className="btn">Print this page</button>
    </div>
  );
}

export default PrintPurchaseOrder;