import React, { useState, useEffect } from 'react';
import { doc, collection, addDoc, setDoc, query, where, orderBy, getDocs, limit } from 'firebase/firestore';
import { db } from './firebase';  // Firestore import
import './AddAsset.css';

function AddAsset({ onClose, showNotification }) {
  const [category, setCategory] = useState('');
  const [dateOfPurchase, setDateOfPurchase] = useState('');
  const [locationInput, setLocationInput] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [assetName, setAssetName] = useState('');
  const [notes, setNotes] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [supplier, setSupplier] = useState('');
  const [assetID, setAssetID] = useState('');

  // Autogenerate AssetID based on location
  const generateAssetID = async () => {
    const prefix = locationInput.substring(0, 3).toUpperCase();  // First 3 letters of location
    let newAssetID = prefix + "01000";  // Default Asset ID to start

    try {
      const assetCollection = collection(db, 'Assets');
      const q = query(assetCollection, where('location', '==', locationInput), orderBy('assetID', 'desc'), limit(1));
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        const highestAsset = snapshot.docs[0].data();
        const currentNumber = parseInt(highestAsset.assetID.substring(3)) + 1;
        newAssetID = prefix + currentNumber.toString().padStart(5, '0');
      }
    } catch (error) {
      console.error('Error generating AssetID:', error);
    }

    setAssetID(newAssetID);
  };

  useEffect(() => {
    if (locationInput.length >= 3) {
      generateAssetID();
    }
  }, [locationInput]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const assetDocRef = doc(db, 'Assets', assetID);
      await setDoc(assetDocRef, {
        assetID,
        category,
        dateOfPurchase,
        location: locationInput,
        manufacturer,
        name: assetName,
        notes,
        price: parseFloat(price),
        quantity: parseInt(quantity),
        serialNumber,
        supplier,
      });

      await addDoc(collection(assetDocRef, 'AssetInventory'), {
        assetID,
        category,
        dateOfPurchase,
        location: locationInput,
        manufacturer,
        name: assetName,
        notes,
        price: parseFloat(price),
        quantity: parseInt(quantity),
        serialNumber,
        supplier,
      });

      showNotification('Asset added successfully!', 'success');
      onClose();
    } catch (error) {
      console.error('Error adding asset:', error.message);
      showNotification('Failed to add asset: ' + error.message, 'error');
    }
  };

  return (
    <div className="add-asset-container">
      <h2>Add Asset</h2>
      <form onSubmit={handleSubmit}>
        {/* Form Row for AssetID, Category, Date of Purchase, and Location */}
        <div className="form-row">
          <div className="form-group">
            <label>Asset ID (Autogenerated)</label>
            <input type="text" value={assetID} readOnly />
          </div>
          <div className="form-group">
            <label>Category</label>
            <input type="text" value={category} onChange={(e) => setCategory(e.target.value)} required />
          </div>
          <div className="form-group">
            <label>Date of Purchase</label>
            <input type="date" value={dateOfPurchase} onChange={(e) => setDateOfPurchase(e.target.value)} required />
          </div>
          <div className="form-group">
            <label>Location</label>
            <input type="text" value={locationInput} onChange={(e) => setLocationInput(e.target.value)} required />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Manufacturer</label>
            <input type="text" value={manufacturer} onChange={(e) => setManufacturer(e.target.value)} required />
          </div>
          <div className="form-group">
            <label>Name</label>
            <input type="text" value={assetName} onChange={(e) => setAssetName(e.target.value)} required />
          </div>
        </div>

        <div className="form-group">
          <label>Notes</label>
          <textarea value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Price</label>
            <input type="number" step="0.01" value={price} onChange={(e) => setPrice(e.target.value)} required />
          </div>
          <div className="form-group">
            <label>Quantity</label>
            <input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
          </div>
          <div className="form-group">
            <label>Serial Number</label>
            <input type="text" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} required />
          </div>
        </div>

        <div className="form-group">
          <label>Supplier</label>
          <input type="text" value={supplier} onChange={(e) => setSupplier(e.target.value)} required />
        </div>

        <div className="form-group">
          <button type="submit">Add Asset</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </div>
      </form>
    </div>
  );
}

export default AddAsset;