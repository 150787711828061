import React, { useState, useEffect, useRef } from 'react';
import { auth } from './firebase';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import Assets from './Assets';
import AssetDetail from './AssetDetail';
import PurchaseOrder from './PurchaseOrder';
import PurchaseOrderDetail from './PurchaseOrderDetail'; // Import the new component
import Login from './Login';
import PrintPurchaseOrder from './PrintPurchaseOrder'; // Import the Print component
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebase';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [notification, setNotification] = useState({ message: '', type: '' });

  // Function to trigger notifications
  const showNotification = (message, type) => {
    setNotification({ message, type });

    // Clear notification after 3 seconds
    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser ? authUser : null);
    });
    return () => unsubscribe();
  }, []);

  if (!user) {
    return <Login />;
  }

  return (
    <Router>
      <div className="app-container">
        <Navbar user={user} notification={notification} showNotification={showNotification} />
        <div className="main-content">
          <Sidebar />
          <div className="content-area">
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard user={user} />} />
              <Route path="/assets" element={<Assets showNotification={showNotification} />} />
              <Route path="/assets/:assetId" element={<AssetDetail showNotification={showNotification} />} />
              <Route path="/purchase-order" element={<PurchaseOrder showNotification={showNotification} />} />
              <Route path="/purchase-order/:purchaseOrderID" element={<PurchaseOrderDetail showNotification={showNotification} />} />
              <Route path="/purchase-order/:purchaseOrderID/print" element={<PrintPurchaseOrder showNotification={showNotification} />} /> {/* New print route */}
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

function Navbar({ user, notification, showNotification }) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const handleSignOut = () => {
    auth.signOut();
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  // Function to handle searching
  const handleSearchChange = async (e) => {
    const queryText = e.target.value;
    setSearchQuery(queryText);

    if (queryText.trim()) {
      // Fetch matching assets
      const assetQuery = query(
        collection(db, 'Assets'),
        where('name', '>=', queryText),
        where('name', '<=', queryText + '\uf8ff')
      );
      const assetSnapshot = await getDocs(assetQuery);
      const assetResults = assetSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        type: 'asset',
      }));

      // Fetch matching purchase orders
      const poQuery = query(
        collection(db, 'PurchaseOrders'),
        where('purposeOfRequest', '>=', queryText),
        where('purposeOfRequest', '<=', queryText + '\uf8ff')
      );
      const poSnapshot = await getDocs(poQuery);
      const poResults = poSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        type: 'purchaseOrder',
      }));

      setSearchResults([...assetResults, ...poResults]);
    } else {
      setSearchResults([]);
    }
  };

  const handleResultClick = (result) => {
    if (result.type === 'asset') {
      navigate(`/assets/${result.id}`);
    } else if (result.type === 'purchaseOrder') {
      navigate(`/purchase-order/${result.id}`);
    }
    setSearchQuery('');
    setSearchResults([]);
  };

  return (
    <div className={`navbar ${notification.type}`}>
      <div className="navbar-left">
        <img src="/assets/app-logo.png" alt="App Logo" className="app-logo" />
        <h1 className="navbar-title">Asset Management</h1>
      </div>
      <div className="navbar-search">
        <input
          type="text"
          placeholder="Search for assets or purchase orders..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input"
        />
        {searchResults.length > 0 && (
          <div className="search-results">
            <h3>Assets</h3>
            {searchResults
              .filter((item) => item.type === 'asset')
              .map((result) => (
                <div
                  key={result.id}
                  className="search-result-item"
                  onClick={() => handleResultClick(result)}
                >
                  {result.name}
                </div>
              ))}
            <h3>Purchase Orders</h3>
            {searchResults
              .filter((item) => item.type === 'purchaseOrder')
              .map((result) => (
                <div
                  key={result.id}
                  className="search-result-item"
                  onClick={() => handleResultClick(result)}
                >
                  {result.purposeOfRequest}
                </div>
              ))}
          </div>
        )}
      </div>
      <div className="user-menu" onClick={toggleDropdown}>
        <img src={user.photoURL || '/assets/user-icon.png'} alt="User Icon" className="user-icon" />
        <div ref={dropdownRef} className={`dropdown ${dropdownVisible ? 'show' : ''}`}>
          <ul>
            <li>View Account</li>
            <li onClick={handleSignOut}>Sign Out</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

function Sidebar() {
  return (
    <div className="sidebar">
      <ul>
        <li><Link to="/dashboard">Dashboard</Link></li>
        <li><Link to="/assets">Assets</Link></li>
        <li><Link to="/purchase-order">Purchase Order</Link></li>
      </ul>
    </div>
  );
}

export default App;