// src/Login.js
import React from 'react';
import { auth } from './firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import './Login.css'; // Import the CSS file for styling

function Login() {
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ hd: 'pmcc4w.ca' });  // Restrict to your domain
    signInWithPopup(auth, provider).catch((error) => {
      console.error("Error during sign-in", error);
    });
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="logo-container">
          <img src="/assets/church-logo.png" alt="Church Logo" className="logo" />
          <img src="/assets/app-logo.png" alt="App Logo" className="logo" />
        </div>
        <h1 className="app-title">Asset Management</h1>
        <button onClick={signInWithGoogle} className="google-signin-btn">
          <img src="/assets/google-logo.png" alt="Google Logo" className="google-logo" />
          Sign in with Google Workspace
        </button>
      </div>
    </div>
  );
}

export default Login;