import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { db } from './firebase';
import { QRCodeSVG } from 'qrcode.react'; // Import QRCodeSVG
import './AssetDetail.css';

function AssetDetail({ showNotification }) {
  const { assetId } = useParams();
  const [asset, setAsset] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // State to track if in edit mode
  const [updatedAsset, setUpdatedAsset] = useState({}); // State to store the updated asset fields

  // Fetch asset details from Firestore
  const fetchAssetDetails = async () => {
    try {
      const assetDocRef = doc(db, 'Assets', assetId);
      const assetDoc = await getDoc(assetDocRef);
      if (assetDoc.exists()) {
        const assetData = assetDoc.data();
        setAsset(assetData);
        setUpdatedAsset(assetData); // Set initial form values to the existing asset data
      } else {
        console.error('No such asset exists!');
      }
    } catch (error) {
      console.error('Error fetching asset details:', error);
      showNotification('Failed to fetch asset details', 'error');
    }
  };

  useEffect(() => {
    fetchAssetDetails();
  }, [assetId]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedAsset({ ...updatedAsset, [name]: value });
  };

  // Save changes to Firestore
  const handleSaveChanges = async () => {
    try {
      const assetDocRef = doc(db, 'Assets', assetId);
      await updateDoc(assetDocRef, updatedAsset);
      setAsset(updatedAsset); // Update the local asset state
      setIsEditing(false); // Exit edit mode
      showNotification('Asset updated successfully!', 'success');
    } catch (error) {
      console.error('Error updating asset:', error);
      showNotification('Failed to update asset', 'error');
    }
  };

  return (
    <div className="asset-detail-container">
      {asset ? (
        <>
          <h1>{asset.assetID}</h1>
          <div className="asset-detail-info">
            <div className="asset-detail-content">
              {isEditing ? (
                <>
                  <div className="form-group">
                    <label>Status</label>
                    <select name="status" value={updatedAsset.status} onChange={handleInputChange}>
                      <option value="Active">Active</option>
                      <option value="Decommissioned">Decommissioned</option>
                      <option value="Needs repairing">Needs repairing</option>
                      <option value="Repairing">Repairing</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Category</label>
                    <input
                      type="text"
                      name="category"
                      value={updatedAsset.category}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group">
                    <label>Date of Purchase</label>
                    <input
                      type="date"
                      name="dateOfPurchase"
                      value={updatedAsset.dateOfPurchase}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group">
                    <label>Location</label>
                    <input
                      type="text"
                      name="location"
                      value={updatedAsset.location}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group">
                    <label>Manufacturer</label>
                    <input
                      type="text"
                      name="manufacturer"
                      value={updatedAsset.manufacturer}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      value={updatedAsset.name}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group">
                    <label>Notes</label>
                    <textarea
                      name="notes"
                      value={updatedAsset.notes}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group">
                    <label>Price</label>
                    <input
                      type="number"
                      step="0.01"
                      name="price"
                      value={updatedAsset.price}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group">
                    <label>Serial Number</label>
                    <input
                      type="text"
                      name="serialNumber"
                      value={updatedAsset.serialNumber}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group">
                    <label>Supplier</label>
                    <input
                      type="text"
                      name="supplier"
                      value={updatedAsset.supplier}
                      onChange={handleInputChange}
                    />
                  </div>

                  <button className="btn" onClick={handleSaveChanges}>
                    Save Changes
                  </button>
                  <button className="btn" onClick={() => setIsEditing(false)}>
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <p><strong>Status:</strong> {asset.status}</p>
                  <p><strong>Category:</strong> {asset.category}</p>
                  <p><strong>Date of Purchase:</strong> {asset.dateOfPurchase}</p>
                  <p><strong>Location:</strong> {asset.location}</p>
                  <p><strong>Manufacturer:</strong> {asset.manufacturer}</p>
                  <p><strong>Name:</strong> {asset.name}</p>
                  <p><strong>Notes:</strong> {asset.notes}</p>
                  <p><strong>Price:</strong> ${asset.price}</p>
                  <p><strong>Serial Number:</strong> {asset.serialNumber}</p>
                  <p><strong>Supplier:</strong> {asset.supplier}</p>
                  <p><strong>Enrolled by:</strong> {asset.enrolledBy}</p>

                  <button className="btn" onClick={() => setIsEditing(true)}>
                    Edit Asset
                  </button>
                </>
              )}
            </div>
            {/* QR Code Section */}
            <div className="qr-code-container">
              <QRCodeSVG value={`Asset ID: ${asset.assetID}`} size={128} /> {/* QR code with asset ID */}
            </div>
          </div>
        </>
      ) : (
        <p>Loading asset details...</p>
      )}
    </div>
  );
}

export default AssetDetail;