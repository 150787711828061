import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from './firebase';  // Firestore import
import './PurchaseOrder.css';  // Add CSS for button styling
import PurchaseOrderForm from './PurchaseOrderForm';  // Import the form

function PurchaseOrder({ showNotification }) {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const navigate = useNavigate();

  // Fetch purchase orders from Firestore
  const fetchPurchaseOrders = async () => {
    try {
      const purchaseOrderCollection = collection(db, 'PurchaseOrders');
      const poSnapshot = await getDocs(purchaseOrderCollection);
      const poList = poSnapshot.docs.map((doc) => ({
        id: doc.id,  // Document ID (purchaseOrderID)
        ...doc.data(),  // All the fields in the document
      }));
      setPurchaseOrders(poList);
    } catch (error) {
      console.error('Error fetching purchase orders:', error);
      showNotification('Failed to fetch purchase orders', 'error');
    }
  };

  useEffect(() => {
    fetchPurchaseOrders();
  }, []);

  // Show Add Purchase Order form
  const handleAdd = () => {
    setShowAddForm(true);
  };

  // Close Add Purchase Order form
  const handleCloseAddForm = () => {
    setShowAddForm(false);
    fetchPurchaseOrders();  // Refresh purchase order list after adding
  };

  // Navigate to purchase order detail page
  const handleViewDetail = (purchaseOrderID) => {
    navigate(`/purchase-order/${purchaseOrderID}`);
  };

  // Handle print button for each purchase order
  const handlePrint = (purchaseOrderID) => {
    navigate(`/purchase-order/${purchaseOrderID}/print`);
  };

  return (
    <div className="purchase-order-container">
      <h1>Purchase Orders</h1>
      <div className="button-container">
        <button className="btn" onClick={handleAdd}>Add</button>
      </div>

      {/* Show PurchaseOrderForm if showAddForm is true */}
      {showAddForm && (
        <PurchaseOrderForm
          onClose={handleCloseAddForm}
          showNotification={showNotification}
        />
      )}

      {/* Display purchase orders in a table */}
      <table className="purchase-order-table">
        <thead>
          <tr>
            <th>Purchase Order ID</th>
            <th>Purpose of Request</th>
            <th>Items Count</th>
            <th>Grand Total</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {purchaseOrders.length > 0 ? (
            purchaseOrders.map((po) => (
              <tr key={po.id}>
                <td>{po.id}</td>
                <td>{po.purposeOfRequest || 'N/A'}</td>
                <td>{po.items ? po.items.length : 0}</td>
                <td>${po.grandTotal || 0}</td>
                <td>
                  <button
                    className="btn view-detail-btn"
                    onClick={() => handleViewDetail(po.id)}
                  >
                    View Details
                  </button>
                  <button
                    className="btn print-btn"
                    onClick={() => handlePrint(po.id)}
                  >
                    Print
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No purchase orders found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PurchaseOrder;