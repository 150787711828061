import React, { useState, useEffect } from 'react';
import { collection, setDoc, doc, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from './firebase';  // Firestore import
import './PurchaseOrderForm.css';  // Separate CSS file for form styling

function PurchaseOrderForm({ onClose, showNotification }) {
  const [purchaseOrderID, setPurchaseOrderID] = useState('');
  const [date, setDate] = useState('');
  const [requestedBy, setRequestedBy] = useState('');
  const [originator, setOriginator] = useState('');
  const [ministry, setMinistry] = useState('');
  const [paymentTerms, setPaymentTerms] = useState('Cheque');  // Default to 'Cheque'
  const [paymentTermsInfo, setPaymentTermsInfo] = useState('');
  const [purposeOfRequest, setPurposeOfRequest] = useState('');
  const [items, setItems] = useState([{ quantity: '', item: '', itemCost: '', itemSubtotal: '' }]);  // Dynamic rows
  const [grandTotal, setGrandTotal] = useState(0);

  // Autogenerate purchaseOrderID based on the pattern "PO001000"
  const generatePurchaseOrderID = async () => {
    let newPOID = 'PO001000';  // Default PO ID

    try {
      const poCollection = collection(db, 'PurchaseOrders');
      const q = query(poCollection, orderBy('purchaseOrderID', 'desc'), limit(1));
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        const highestPO = snapshot.docs[0].data();
        const currentNumber = parseInt(highestPO.purchaseOrderID.substring(2)) + 1;
        newPOID = 'PO' + currentNumber.toString().padStart(6, '0');
      }
    } catch (error) {
      console.error('Error generating PurchaseOrderID:', error);
    }

    setPurchaseOrderID(newPOID);
  };

  // Call generatePurchaseOrderID when the component mounts
  useEffect(() => {
    generatePurchaseOrderID();
  }, []);

  // Handle adding a new row to the items list
  const addItemRow = () => {
    setItems([...items, { quantity: '', item: '', itemCost: '', itemSubtotal: '' }]);
  };

  // Handle removing a row from the items list
  const removeItemRow = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    calculateGrandTotal(updatedItems);  // Recalculate grand total after removing a row
  };

  // Handle updating the values of the items
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;

    // Calculate item subtotal
    if (field === 'quantity' || field === 'itemCost') {
      const quantity = parseFloat(updatedItems[index].quantity || 0);
      const itemCost = parseFloat(updatedItems[index].itemCost || 0);
      updatedItems[index].itemSubtotal = (quantity * itemCost).toFixed(2);
    }

    setItems(updatedItems);
    calculateGrandTotal(updatedItems);  // Recalculate grand total after updating
  };

  // Calculate grand total
  const calculateGrandTotal = (updatedItems) => {
    const total = updatedItems.reduce((sum, item) => sum + parseFloat(item.itemSubtotal || 0), 0);
    setGrandTotal(total.toFixed(2));
  };

  // Dynamically update the Payment Terms Information based on Payment Terms selection
  const renderPaymentTermsInfo = () => {
    if (paymentTerms === 'Cheque') {
      return (
        <div className="form-group">
          <label>Name of Cheque to</label>
          <input
            type="text"
            value={paymentTermsInfo}
            onChange={(e) => setPaymentTermsInfo(e.target.value)}
            required
          />
        </div>
      );
    } else if (paymentTerms === 'E-transfer') {
      return (
        <div className="form-group">
          <label>What is the e-transfer email?</label>
          <input
            type="email"
            value={paymentTermsInfo}
            onChange={(e) => setPaymentTermsInfo(e.target.value)}
            required
          />
        </div>
      );
    } else if (paymentTerms === 'Wire') {
      return (
        <div className="form-group">
          <label>Wire Payment Details</label>
          <textarea
            value={paymentTermsInfo}
            onChange={(e) => setPaymentTermsInfo(e.target.value)}
            placeholder="name as spelled on the account
address
financial institution information:
account number (no spaces/special characters)
branch name and address (including postal code)
branch's routing info (institution & branch transit numbers)
SWIFT code (if available)
payment amount
payment date (YY/MM/DD)
who pays the service fees?"
            required
          ></textarea>
        </div>
      );
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Use setDoc to ensure purchaseOrderID is the document ID
      await setDoc(doc(db, 'PurchaseOrders', purchaseOrderID), {
        purchaseOrderID,
        date,
        requestedBy,
        originator,
        ministry,
        paymentTerms,
        paymentTermsInfo,
        purposeOfRequest,
        items,
        grandTotal,
      });

      showNotification('Purchase Order submitted successfully!', 'success');
      onClose();
    } catch (error) {
      console.error('Error submitting purchase order:', error.message);
      showNotification('Failed to submit purchase order: ' + error.message, 'error');
    }
  };

  return (
    <div className="purchase-order-form-container">
      <h2>Create Purchase Order</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Purchase Order ID (Autogenerated)</label>
          <input type="text" value={purchaseOrderID} readOnly />
        </div>

        {/* Form Row for Date, Requested By, Originator, and Ministry */}
        <div className="form-row">
          <div className="form-group">
            <label>Date</label>
            <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
          </div>
          <div className="form-group">
            <label>Requested By</label>
            <input type="text" value={requestedBy} onChange={(e) => setRequestedBy(e.target.value)} required />
          </div>
          <div className="form-group">
            <label>Originator</label>
            <input type="text" value={originator} onChange={(e) => setOriginator(e.target.value)} required />
          </div>
          <div className="form-group">
            <label>Ministry</label>
            <input type="text" value={ministry} onChange={(e) => setMinistry(e.target.value)} required />
          </div>
        </div>

        <div className="form-group">
          <label>Payment Terms</label>
          <select value={paymentTerms} onChange={(e) => setPaymentTerms(e.target.value)} required>
            <option value="Cheque">Cheque</option>
            <option value="E-transfer">E-transfer</option>
            <option value="Wire">Wire</option>
          </select>
        </div>

        {/* Dynamically render Payment Terms Information */}
        {renderPaymentTermsInfo()}

        <div className="form-group">
          <label>Purpose of Request</label>
          <textarea value={purposeOfRequest} onChange={(e) => setPurposeOfRequest(e.target.value)} required></textarea>
        </div>

        {/* Dynamic Items Section */}
        <h3>Items</h3>
        {items.map((item, index) => (
          <div className="item-row" key={index}>
            <input
              type="number"
              placeholder="Quantity"
              value={item.quantity}
              onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Item"
              value={item.item}
              onChange={(e) => handleItemChange(index, 'item', e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Item Cost"
              step="0.01"
              value={item.itemCost}
              onChange={(e) => handleItemChange(index, 'itemCost', e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Item Subtotal"
              value={item.itemSubtotal}
              readOnly
            />
            {index > 0 && (
              <button type="button" onClick={() => removeItemRow(index)}>
                Remove
              </button>
            )}
          </div>
        ))}

        {/* Button to add more rows */}
        <button type="button" onClick={addItemRow}>Add Item</button>

        {/* Grand Total */}
        <h3>Grand Total: ${grandTotal}</h3>

        {/* Submit Button */}
        <button type="submit">Submit Purchase Order</button>
      </form>
    </div>
  );
}

export default PurchaseOrderForm;